import { Dropdown, DROPDOWN_MENU_PLACEMENT, Icon, IconButton, Text } from '@optra/kit';

import OrganizationSelect from 'components/organization-select';
import { useCurrentOrganization, useProfile } from 'queries';

const NUM_MAX_PERSONAL_ORGS = 3;

export default function ProfileOrganizationSwitcher() {
  const [currentOrganization, { handleChange: handleChangeCurrentOrg }] = useCurrentOrganization();
  const { isLoading, data: profile } = useProfile();

  const currentUser = profile?.currentUser;
  const canEditCurrentOrg = profile?.currentOrganizationMember?.roles?.includes('admin');
  const currentOrgIsPersonal = currentOrganization.id === '$$NONE';
  const numberOfOwnedOrgs = currentUser?.profile?.ownedOrganizationsCount || 0;

  return (
    <>
      {isLoading && <Text size="xs">Loading…</Text>}
      {!isLoading && currentUser?.memberships?.data?.length < 1 && (
        <Dropdown placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}>
          <Dropdown.Button>
            {({ isOpen }) => (
              <div className="flex flex-row items-center space-x-2">
                <Icon name="User" size="sm" />
                <Text size="xs" variant="label" color="muted">
                  Personal Account
                </Text>
                <Icon name={isOpen ? 'CaretUp' : 'CaretDown'} size="xs" />
              </div>
            )}
          </Dropdown.Button>
          <Dropdown.Body unstyled>
            <Dropdown.Item
              to="/profile/organizations/create"
              icon="Plus"
              text="Create Organization"
            />
          </Dropdown.Body>
        </Dropdown>
      )}
      {currentUser?.memberships?.data?.length > 0 && (
        <div className="flex flex-row items-center space-x-2">
          <OrganizationSelect
            placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
            variant="tertiary"
            placeholderText="Personal"
            allOptionText="Personal"
            allOptionIcon="User"
            allOptionValue="$$NONE"
            size="xs"
            value={currentOrganization.id}
            onChange={handleChangeCurrentOrg}
          >
            <OrganizationSelect.Footer
              to="/profile/organizations/create"
              icon="Plus"
              text="Create Organization"
              hoverSubtle
              className="!rounded-md"
              components={{
                after: (
                  <Text
                    variant="label"
                    size="xs"
                    color={numberOfOwnedOrgs >= NUM_MAX_PERSONAL_ORGS ? 'danger' : 'muted'}
                  >
                    {numberOfOwnedOrgs}/{NUM_MAX_PERSONAL_ORGS}
                  </Text>
                ),
              }}
            />
          </OrganizationSelect>
          {!currentOrgIsPersonal && canEditCurrentOrg && (
            <IconButton
              to={`/profile/organizations/${currentOrganization?.id}/edit`}
              name="Pencil"
              variant="tertiary"
            />
          )}
        </div>
      )}
    </>
  );
}
