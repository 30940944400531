import { useState, useEffect, useRef } from 'react';

import { PrimaryCTAButton, Text, PageWrapper } from '@optra/kit';

import AdminUsageExportsList from 'components/admin-usage-exports-list';
import AdminUsageList from 'components/admin-usage-list';
import IntersectionLoadMore from 'components/intersection-load-more';
import Label from 'components/label';
import OrganizationSelect from 'components/organization-select';
import PinPageButton from 'components/pin-page-button';
import { api, q } from 'config/api';
import pluralize from 'lib/pluralize';
import { useOrganizations, useProvisionedDevicesUsage } from 'queries';

export default function AdminUsagePage() {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('all');

  const Organizations = useOrganizations({ all: true });
  const organizations = Organizations.data;

  const initialized = useRef(false);
  useEffect(() => {
    if (!selectedOrganizationId && !initialized.current) {
      const initialOrganizationId = organizations[0]?.id;
      setSelectedOrganizationId(initialOrganizationId);
      if (initialOrganizationId) initialized.current = true;
    }
  }, [selectedOrganizationId, organizations]);

  const devices = useProvisionedDevicesUsage(
    {
      sort: { direction: 'desc' },
      filter: {
        organizationId: selectedOrganizationId === 'all' ? null : selectedOrganizationId,
      },
    },
    { enabled: !!selectedOrganizationId },
  );

  const qc = q.useQueryClient();
  const exportAllDevices = q.useMutation({
    mutationFn: form =>
      api(
        `mutation exportOrganizationsDevices($form: exportOrganizationsDevicesForm!) {
          csv: exportOrganizationsDevices(form: $form) {
            url
          }
        }`,
        { form },
      ),
    onMutate: form => {
      qc.invalidateQueries({
        queryKey: ['organizationsDeviceUsageExports', form.organizationId],
      });
    },
    onSettled: (r, err, form) => {
      qc.invalidateQueries({
        queryKey: ['organizationsDeviceUsageExports', form.organizationId],
      });
    },
  });

  const usageExports = q.useQuery({
    queryKey: ['organizationsDeviceUsageExports', selectedOrganizationId],
    queryFn: () =>
      api(
        `query organizationsDeviceUsageExports($list: listFields) {
          list: organizationsDeviceUsageExports(list: $list) {
            data {
              id
              url
              timestamp
              organization {
                name
              }
              isAll
              state {
                isTimedOut
                isInProgress
              }
            }
            cursor {
              after
            }
          }
        }`,
        {
          list: {
            limit: 3,
            filter: {
              organizationId: selectedOrganizationId === 'all' ? null : selectedOrganizationId,
            },
          },
        },
      ),
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 10000,
    select: data => data?.list?.data ?? [],
    initialData: [],
  });

  const exportInProgress =
    exportAllDevices.isPending ||
    usageExports.data.some(usageExport => usageExport.state.isInProgress);

  const handleSelectOrganizationId = nextOrganizationId => {
    setSelectedOrganizationId(nextOrganizationId);
  };

  return (
    <PageWrapper
      icon="Activity"
      title="Usage | Admin"
      heading="Usage"
      inline={true}
      loading={devices?.isLoading}
      error={devices?.error}
      components={{
        actions: (
          <>
            <PinPageButton />

            <PrimaryCTAButton
              onClick={async () => {
                if (exportAllDevices.isPending) return;
                exportAllDevices.mutate({
                  organizationId: selectedOrganizationId,
                });
              }}
              loading={exportInProgress}
              icon="DownloadSimple"
              text="Export"
            />
          </>
        ),
        controls: (
          <div className="flex items-center justify-end gap-2">
            <Label>Organization</Label>

            <OrganizationSelect
              onChange={handleSelectOrganizationId}
              value={selectedOrganizationId}
              filters={{ all: true }}
            />
          </div>
        ),
      }}
    >
      <div className="mb-3">
        <Text>Recent Exports</Text>
      </div>
      <AdminUsageExportsList usageExports={usageExports.data} />

      <div className="my-3">
        <Text>{`${devices.data?.length || 0} ${pluralize('Device', devices.data?.length)}`}</Text>
      </div>
      <AdminUsageList fetching={devices?.isLoading} devices={devices.data} />

      <IntersectionLoadMore
        onVisible={devices.fetchNextPage}
        disabled={!devices.hasNextPage}
        isLoading={devices.isFetchingNextPage}
      />
    </PageWrapper>
  );
}
