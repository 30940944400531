import { api, q } from 'config/api';

export default function useAdminDeviceUsage(provisionedDeviceId, overrides) {
  return q.useQuery({
    queryKey: ['adminDeviceUsage', provisionedDeviceId],
    queryFn: () =>
      api(
        `query adminDeviceUsage($id: ID) {
          provisionedDevice(id: $id) {
            id
            ytdMessages  
          }
        }`,
        { id: provisionedDeviceId },
      ),
    select: data => {
      return data.provisionedDevice.ytdMessages;
    },
    ...overrides,
  });
}
