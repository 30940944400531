import { flatMap, uniq } from 'lodash';
import { useEffect, useState } from 'react';

import { Button, Modal, Table, Text } from '@optra/kit';

import SkillIcon from 'components/skill-icon';
import SkillsChooser from 'components/skills-chooser';
import { useLibrarySkills } from 'queries';

function SkillsSelectorRowDataComponent(props) {
  const { skill } = props;
  return (
    <>
      <Table.TD>
        <SkillIcon
          size="sm"
          color={skill?.color}
          icon={skill?.icon}
          iconUrl={skill?.iconUrl}
          circle={skill?.edgeImpulse?.id}
        />
      </Table.TD>
      <Table.TD>
        <Text size="sm">{skill.name}</Text>
      </Table.TD>
    </>
  );
}

export default function SkillsSelector(props) {
  const { handleSelectSkill, isOpen, reset, selectButtonText = 'Select', setIsOpen } = props;
  const [filter, setFilter] = useState({});
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [deselectedSkills, setDeselectedSkills] = useState([]);

  const updateSelectedSkills = (selected, skill) => {
    if (selected) {
      setSelectedSkills(uniq(selectedSkills.filter(s => s !== skill.id)));
      setDeselectedSkills(uniq([...deselectedSkills, skill.id]));
    } else {
      setSelectedSkills(uniq([...selectedSkills, skill.id]));
      setDeselectedSkills(uniq(deselectedSkills.filter(d => d !== skill.id)));
    }
  };

  const skills = useLibrarySkills(
    {
      list: {
        filter,
        sort: {
          direction: 'desc',
        },
      },
    },
    undefined,
    true,
  );

  const list = flatMap(skills.data?.pages, page => page?.list?.data);

  useEffect(() => {
    if (reset) {
      setSelectedSkills([]);
      setDeselectedSkills([]);
      setFilter({});
    }
  }, [reset]);

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Header heading="Choose Skills" />
      <Modal.Body>
        <SkillsChooser
          isLoading={skills?.isLoading}
          skills={list}
          error={skills.error}
          hasNextPage={skills?.hasNextPage}
          fetchNextPage={skills?.fetchNextPage}
          isFetchingNextPage={skills?.isFetchingNextPage}
          onSelectSkill={updateSelectedSkills}
          filter={filter}
          onFilter={setFilter}
          newSelectedSkills={selectedSkills}
          newDeselectedSkills={deselectedSkills}
          RowDataComponent={SkillsSelectorRowDataComponent}
        />
      </Modal.Body>
      <Modal.Footer className="justify-center">
        <Button
          size="xl"
          loading={skills?.isLoading}
          onClick={() => {
            handleSelectSkill({ push: selectedSkills, pull: deselectedSkills });
            setIsOpen(false);
          }}
        >
          {selectButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
