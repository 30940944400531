import { api, q } from 'config/api';

export default function useProvisionedDevicesUsage(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['provisionedDevicesUsage', variables],
    queryFn({ pageParam }) {
      return api(
        `query provisionedDevicesUsage($list: listFields) {
          list: provisionedDevices(list: $list) {
            count
            cursor {
              after
            }
            data {
              id
              serialNumber
              model
              createdAt
              device {
                id
                status
                autoClaim
                location {
                  address {
                    address
                    locality
                  }
                }
              }
              organization {
                name
              }
              claimantId
            }
          }
        }`,
        {
          list: {
            ...variables,
            cursor: { after: pageParam },
          },
        },
      );
    },
    placeholderData: q.keepPreviousData,
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    select: data => data?.pages.flatMap(page => page.list.data),
    ...overrides,
  });
}
