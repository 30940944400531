import { flatMap, uniq } from 'lodash';
import { useEffect, useState } from 'react';

import { Button, Modal, Table, Text } from '@optra/kit';

import TagsChooser from 'components/tags-chooser';
import { useTags } from 'queries';

function TagsSelectorRowDataComponent(props) {
  const { tag } = props;
  return (
    <Table.TD>
      <Text size="sm">{tag.name}</Text>
    </Table.TD>
  );
}

export default function TagsSelector(props) {
  const { handleSelectTag, isOpen, reset, selectButtonText = 'Select', setIsOpen } = props;
  const [filter, setFilter] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [deselectedTags, setDeselectedTags] = useState([]);

  const updateSelectedTags = (selected, tag) => {
    if (selected) {
      setSelectedTags(uniq(selectedTags.filter(s => s !== tag.id)));
      setDeselectedTags(uniq([...deselectedTags, tag.id]));
    } else {
      setSelectedTags(uniq([...selectedTags, tag.id]));
      setDeselectedTags(uniq(deselectedTags.filter(d => d !== tag.id)));
    }
  };

  const tags = useTags(
    {
      list: {
        filter,
        sort: {
          direction: 'desc',
        },
      },
    },
    undefined,
    true,
  );

  const list = flatMap(tags.data?.pages, page => page?.list?.data);

  useEffect(() => {
    if (reset) {
      setSelectedTags([]);
      setDeselectedTags([]);
      setFilter({});
    }
  }, [reset]);

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Header heading="Choose Tags" />
      <Modal.Body>
        <TagsChooser
          isLoading={tags?.isLoading}
          tags={list}
          error={tags.error}
          hasNextPage={tags?.hasNextPage}
          fetchNextPage={tags?.fetchNextPage}
          isFetchingNextPage={tags?.isFetchingNextPage}
          onSelectTag={updateSelectedTags}
          filter={filter}
          onFilter={setFilter}
          newSelectedTags={selectedTags}
          newDeselectedTags={deselectedTags}
          RowDataComponent={TagsSelectorRowDataComponent}
        />
      </Modal.Body>
      <Modal.Footer className="justify-center">
        <Button
          size="xl"
          loading={tags?.isLoading}
          onClick={() => {
            handleSelectTag({ push: selectedTags, pull: deselectedTags });
            setIsOpen(false);
          }}
        >
          {selectButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
