import IntersectionLoadMore from 'components/intersection-load-more';
import WorkflowActionListItem from 'components/workflow-action-list-item';
import WorkflowCard from 'components/workflow-card';
import { useHasRoles } from 'hooks';
import { useWorkflowsActions } from 'queries';

export default function WorkflowActions(props) {
  const { workflowId, shouldFetch, expanded = true, disabled = false } = props;
  const [canEdit] = useHasRoles(['admin', 'workflowEditor', 'workflowDeployer']);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useWorkflowsActions(
    workflowId,
    {
      filter: { draft: false },
      limit: 12,
    },
    {
      enabled: !!workflowId && shouldFetch,
    },
  );
  const { count, actions } = data ?? {};

  return (
    <WorkflowCard
      icon="Lightning"
      heading="Actions"
      plusUrl={`/workflows/${workflowId}/workflow-actions/add`}
      count={count}
      loading={isLoading}
      disabled={!canEdit || disabled}
      expanded={expanded}
    >
      {actions
        ?.filter(node => !!node)
        ?.map?.(workflowAction => (
          <WorkflowActionListItem
            key={workflowAction.id}
            id={workflowAction.id}
            workflowId={workflowId}
            name={workflowAction.action.name}
            type={workflowAction.action.id}
            dirty={!workflowAction.synchronized && !workflowAction.isSynchronizing}
            deleted={workflowAction.deleted}
            disabled={disabled}
            actionDisabled={workflowAction.disabled}
          />
        ))}

      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isLoading || isFetchingNextPage || !hasNextPage}
      />
    </WorkflowCard>
  );
}
