import { useNavigate } from 'react-router-dom';

import { Table, Text, Spinner } from '@optra/kit';

import DeviceImage from 'components/device-image';
import useIsVisible from 'hooks/use-is-visible';
import useAdminDeviceUsage from 'queries/use-admin-device-usage';

export default function AdminUsageListItem({ provisionedDevice }) {
  const navigate = useNavigate();
  const [ref, isVisible] = useIsVisible();

  const { device, id: provisionedDeviceId } = provisionedDevice || {};
  const enabledForQuery = isVisible && !!provisionedDeviceId;
  const { data: ytdMessages, isLoading } = useAdminDeviceUsage(
    provisionedDeviceId,
    enabledForQuery,
  );

  const handleClick = () => {
    if (device?.id) {
      navigate(`/admin/usage/${device.id}`);
    }
  };

  return (
    <Table.Row hover caret onClick={handleClick} ref={ref}>
      <Table.TD collapsed>
        <DeviceImage
          model={provisionedDevice?.model}
          status={device?.autoClaim ? 'pending' : device?.status}
          hideStatus={!device?.status}
          size="sm"
        />
      </Table.TD>
      <Table.TD>
        <Text className="text-xs uppercase font-mono">{provisionedDevice?.serialNumber}</Text>
      </Table.TD>
      <Table.TD>
        <Text size="xs" color="muted">
          {device?.ownerName}
        </Text>
      </Table.TD>
      <Table.TD>
        {isLoading ? (
          <Spinner color="primary" size="sm" />
        ) : (
          <Text size="xs" color="muted">
            {ytdMessages ?? 0}
          </Text>
        )}
      </Table.TD>
      <Table.TD>
        <Text size="xs" color="muted" className="block">
          {device?.location?.address?.address}
        </Text>
        <Text size="xs" color="muted" className="block">
          {device?.location?.address?.locality}
        </Text>
      </Table.TD>
      <Table.TD collapsed>
        {!provisionedDeviceId && (
          <Text size="xs" color="muted">
            Removed
          </Text>
        )}
      </Table.TD>
    </Table.Row>
  );
}
