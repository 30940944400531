import { isEmpty } from 'lodash';

import { Table } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';

import AdminUsageListItem from './admin-usage-list-item';

export default function AdminUsageList({ devices = [] }) {
  if (isEmpty(devices)) {
    return <EmptyContentMessage icon="Activity" title="No Usage" />;
  }

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.TH />
          <Table.TH>Serial Number</Table.TH>
          <Table.TH>Owner</Table.TH>
          <Table.TH>YTD Messages Transmitted</Table.TH>
          <Table.TH>Location</Table.TH>
          <Table.TH />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {devices.map(provisionedDevice => {
          return (
            <AdminUsageListItem key={provisionedDevice.id} provisionedDevice={provisionedDevice} />
          );
        })}
      </Table.Body>
    </Table>
  );
}
