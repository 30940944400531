import { Layout, Cursor } from '@phosphor-icons/react';
import { isEmpty } from 'lodash';
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';

import { UiState, Button, PageWrapper } from '@optra/kit';

import AddWorkspaceButton from 'components/charts/add-workspace-button';
import ChartsSidebar from 'components/charts/charts-sidebar';
import ChartsWorkspace from 'components/charts/charts-workspace';
import { api, q } from 'config/api';

export default function Workspaces() {
  const InitialWorkspace = q.useQuery({
    queryKey: ['workspaces', 'initial'],
    queryFn: () =>
      api(
        `query initialWorkspace {
          workspaces(list: { limit: 1, sort: { by: "createdAt", direction: desc } }) {
            data {
              id
            }
          }
        }`,
      ),
    select: result => result?.workspaces?.data?.[0],
    refetchOnWindowFocus: false,
  });

  const hasInitialWorkspace =
    !isEmpty(InitialWorkspace.data) && InitialWorkspace.isFetched && !InitialWorkspace.isRefetching;

  return (
    <Routes>
      <Route
        element={
          <PageWrapper
            loading={InitialWorkspace.isLoading || InitialWorkspace.isRefetching}
            error={InitialWorkspace.error}
          >
            <PageWrapper.Left>{({ close }) => <ChartsSidebar close={close} />}</PageWrapper.Left>
            <PageWrapper.LeftToggle>
              <Button variant="secondary" size="sm" as="span">
                Workspaces
              </Button>
            </PageWrapper.LeftToggle>

            {hasInitialWorkspace ? (
              <Outlet />
            ) : (
              <UiState
                icon={{ component: Layout }}
                variant="zero"
                text="No workspaces created"
                center
              >
                <AddWorkspaceButton variant="primary" />
              </UiState>
            )}
          </PageWrapper>
        }
      >
        <Route
          index
          element={<Navigate to={hasInitialWorkspace ? InitialWorkspace.data?.id : undefined} />}
        />
        <Route path=":workspaceId/*" element={<ChartsWorkspace />} />
        <Route
          path="*"
          element={
            <UiState icon={{ component: Cursor }} variant="empty" text="No workspace selected" />
          }
        />
      </Route>
    </Routes>
  );
}
