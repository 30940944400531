import { api, q } from 'config/api';

export default function useSkillVersion(id, overrides) {
  return q.useQuery({
    queryKey: ['skillVersion', id],
    queryFn: () =>
      api(
        `query skillVersion($id: ID!) {
          skillVersion(id: $id) {
            id
            outputs(excludeDefaults: true) {
              id
              label
              mapping {
                type
                statusMapping {
                  upperThreshold
                  lowerThreshold
                  direction
                }
              }
              unhealthyTimeoutMS
            }
            inputs {
              label
              binding
              type
            }
            repository {
              uri
              username
              password
            }
            skill {
              versions {
                count
              }
            }
            createOptions
            storage
            hostName
            endpointAliases
            portBindings
            tmpfs
            shmSize
            port
            protocol
            removableMedia
            hostNetworking
            gpio
            cx2000IRRemote
            usbSerialConverter
            cx2000VideoAcceleration
            dockerInDocker
            privileged
            mountVolumes
            led
            binds
            capAdd
            capDrop
            addedDevice
            writableRootFS
            devices {
              sound
              hdmi
              cameras
            }
            env
            dockerCompose {
              key
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    // refetchInterval: 5000,
    ...overrides,
  });
}
