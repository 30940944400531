import { uniqueId, isEmpty, isFinite as _isFinite, isBoolean } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@optra/kit';

import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import RawConfig from 'components/raw-config';
import SkillComposeFields from 'components/skill-compose-fields';
import SkillComposePrivilegesFields from 'components/skill-compose-privileges-fields';
import SkillContainerFields from 'components/skill-container-fields';
import SkillEnvFields from 'components/skill-env-fields';
import SkillFormTabs, { isActiveTab } from 'components/skill-form-tabs';
import SkillInputFields from 'components/skill-input-fields';
import SkillOutputFields from 'components/skill-output-fields';
import SkillPrivilegesFields, {
  FIELD_NAMES as PRIVILEGES_FIELD_NAMES,
} from 'components/skill-privileges-fields';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useItemNotFound } from 'hooks';
import cleanInputArray from 'lib/clean-input-array';
import ItemNotFound from 'modals/item-not-found';
import { useCurrentUser, useSignedUpload, useSkillVersion } from 'queries';

export default function EditSkillVersion() {
  const { skillVersionId, skillId } = useParams();
  const navigate = useNavigate();
  const form = useForm();
  const {
    control,
    formState: { errors },
    handleSubmit: onSubmit,
    register,
    reset,
    watch,
  } = form;
  const [error, setError] = useState();
  const [tab, setTab] = useState('container|compose');
  const [currentUser] = useCurrentUser();
  const [upload, uploadState] = useSignedUpload({ type: 'dockerCompose' });

  // Make the password field look filled if the registry has a password
  const passwordPlaceholder = password => (password ? '             ' : '');

  const {
    data,
    isLoading: fetching,
    isSuccess,
    error: fetchError,
  } = useSkillVersion(skillVersionId);
  const isDockerCompose = !isEmpty(data?.skillVersion?.dockerCompose);
  const isLastVersion = data?.skillVersion?.skill?.versions?.count === 1;

  useOnSuccess(
    () => {
      reset({
        repository: {
          uri: data?.skillVersion?.repository?.uri,
          username: data?.skillVersion?.repository?.username,
          password: passwordPlaceholder(data?.skillVersion?.repository?.password),
        },
        inputs: (data?.skillVersion?.inputs || []).map(i => ({
          _id: uniqueId(),
          key: i.binding,
          value: i.label,
          type: i.type,
        })),
        outputs: (data?.skillVersion?.outputs || []).map(o => ({
          _id: uniqueId(),
          key: o.id,
          value: o.label,
          mapping: {
            type: o.mapping?.type || '',
            statusMapping: o.mapping?.statusMapping || null,
          },
          unhealthyTimeoutMS: o.unhealthyTimeoutMS || 1000 * 60 * 60, // Default
        })),
        env: Object.entries(data?.skillVersion?.env || {}).map(([key, value]) => ({
          _id: uniqueId(),
          key,
          value,
        })),
        devices: {
          sound: data?.skillVersion?.devices?.sound,
          hdmi: data?.skillVersion?.devices?.hdmi,
          // For now, this is a toggle. In the future, we may allow the user to choose a number
          cameras: _isFinite(data?.skillVersion?.devices?.cameras),
        },
        removableMedia: data?.skillVersion?.removableMedia,
        hostNetworking: data?.skillVersion?.hostNetworking,
        gpio: data?.skillVersion?.gpio,
        cx2000IRRemote: data?.skillVersion?.cx2000IRRemote,
        usbSerialConverter: data?.skillVersion?.usbSerialConverter,
        cx2000VideoAcceleration: data?.skillVersion?.cx2000VideoAcceleration,
        led: data?.skillVersion?.led,
        dockerInDocker: data?.skillVersion?.dockerInDocker,
        privileged: data?.skillVersion?.privileged,
        mountVolumes: (data?.skillVersion?.mountVolumes || []).map(size => ({
          _id: uniqueId(),
          ...size,
        })),
        binds: (data?.skillVersion?.binds || []).map(bind => ({
          _id: uniqueId(),
          ...bind,
        })),
        capAdd: (data?.skillVersion?.capAdd || []).map(cap => ({
          _id: uniqueId(),
          ...cap,
        })),
        capDrop: (data?.skillVersion?.capDrop || []).map(cap => ({
          _id: uniqueId(),
          ...cap,
        })),
        addedDevice: (data?.skillVersion?.addedDevice || []).map(device => ({
          _id: uniqueId(),
          ...device,
        })),
        writableRootFS: data?.skillVersion?.writableRootFS,
        ...(!isEmpty(data?.skillVersion?.createOptions)
          ? { createOptions: JSON.stringify(data?.skillVersion?.createOptions, null, 2) }
          : { createOptions: '' }),
        storage: Object.entries(data?.skillVersion?.storage || {}).map(([key, value]) => ({
          _id: uniqueId(),
          key,
          value,
        })),
        hostName: data?.skillVersion?.hostName,
        endpointAliases: data?.skillVersion?.endpointAliases?.map(alias => ({
          _id: uniqueId(),
          alias,
        })),
        port: data?.skillVersion?.port,
        protocol: data?.skillVersion?.protocol || 'http',
        portBindings: (data?.skillVersion?.portBindings || []).map(p => ({
          _id: uniqueId(),
          ...p,
        })),
        tmpfs: (data?.skillVersion?.tmpfs || []).map(t => ({
          _id: uniqueId(),
          ...t,
          sizeBytes: t.sizeBytes,
        })),
        shmSize: data?.skillVersion?.shmSize,
        ...(!isEmpty(data?.skillVersion?.createOptions)
          ? { createOptions: JSON.stringify(data?.skillVersion?.createOptions, null, 2) }
          : { createOptions: '' }),
      });
    },
    { isSuccess },
    [data, reset],
  );

  const qc = q.useQueryClient();
  const updateSkillVersion = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateSkillVersion($form: updateSkillVersionForm!) {
          skill: updateSkillVersion(form: $form) {
            id
            skill {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['skillVersion', skillVersionId] });
      navigate(`/skills/${r?.skill?.skill?.id}/edit`);
    },
    onError(err) {
      setError(err);
    },
  });

  const updateDockerComposeSkillVersion = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateDockerComposeSkillVersion($form: updateDockerComposeSkillVersionForm!) {
          version: updateDockerComposeSkillVersion(form: $form) {
            id
            skill {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['skillVersion', skillVersionId] });
      navigate(`/skills/${r?.version?.skill?.id}/edit`);
    },
    onError(err) {
      setError(err);
    },
  });

  const deleteSkillVersion = q.useMutation({
    mutationFn: ({ id }) =>
      api(
        `mutation removeSkillVersion($id: ID!) {
          removeSkillVersion(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['skillVersions'] });
      // NOTE: Should we also invalidate skillVersions and librarySkillVersions like in create-skill-version?
    },
  });

  const handleDeleteVersion = async () => {
    if (window.confirm('Are you sure you want to delete this skill version?')) {
      const r = await deleteSkillVersion.mutateAsync({ id: skillVersionId });
      if (r?.error) {
        setError(r.error);
      } else {
        navigate(`/skills/${skillId}/edit`);
      }
    }
  };

  const handleSubmit = onSubmit(async form => {
    setError(null);
    const env = {};
    cleanInputArray(form.env).forEach(v => {
      env[v.key] = v.value;
    });
    const port = parseInt(form.port);
    if (!isDockerCompose) {
      const storage = {};
      cleanInputArray(form.storage).forEach(v => {
        storage[v.key] = v.value;
      });
      const endpointAliases = cleanInputArray(form.endpointAliases).map(v => v.alias);

      const shmSize = parseInt(form.shmSize);
      const writableRootFS = parseInt(form.writableRootFS);
      updateSkillVersion.mutate({
        id: skillVersionId,
        env,
        storage,
        hostName: !isEmpty(form.hostName) ? form.hostName : null,
        endpointAliases,
        portBindings: cleanInputArray(form.portBindings),
        tmpfs: cleanInputArray(form.tmpfs),
        shmSize: _isFinite(shmSize) ? shmSize : null,
        port: _isFinite(port) ? port : null,
        protocol: form.protocol,
        removableMedia: isBoolean(form.removableMedia) ? form.removableMedia : null,
        hostNetworking: isBoolean(form.hostNetworking) ? form.hostNetworking : null,
        gpio: isBoolean(form.gpio) ? form.gpio : null,
        cx2000IRRemote: isBoolean(form.cx2000IRRemote) ? form.cx2000IRRemote : null,
        usbSerialConverter: isBoolean(form?.usbSerialConverter) ? form?.usbSerialConverter : null,
        cx2000VideoAcceleration: isBoolean(form?.cx2000VideoAcceleration)
          ? form?.cx2000VideoAcceleration
          : null,
        led: isBoolean(form?.led) ? form.led : null,
        dockerInDocker: isBoolean(form?.dockerInDocker) ? form.dockerInDocker : null,
        privileged: isBoolean(form?.privileged) ? form?.privileged : null,
        mountVolumes: cleanInputArray(form?.mountVolumes),
        binds: cleanInputArray(form?.binds),
        capAdd: cleanInputArray(form?.capAdd),
        capDrop: cleanInputArray(form?.capDrop),
        addedDevice: cleanInputArray(form?.addedDevice),
        writableRootFS: _isFinite(writableRootFS) ? writableRootFS : null,
        devices: {
          ...form.devices,
          // For now, we force 5 cameras. In the future, we may allow the user to choose a number
          cameras: form.devices.cameras ? 5 : null,
        },
        repository: {
          ...form.repository,
          password:
            // Only update the password if it has changed
            form.repository.password !==
            passwordPlaceholder(data?.skillVersion?.repository?.password)
              ? form.repository.password
              : undefined,
        },
        outputs: cleanInputArray(form.outputs).map(o => ({
          id: o.key,
          label: o.value,
          mapping: {
            type: o.mapping?.type || null,
            statusMapping: o.mapping?.statusMapping || null,
          },
          unhealthyTimeoutMS: _isFinite(parseInt(o.unhealthyTimeoutMS))
            ? parseInt(o.unhealthyTimeoutMS)
            : null,
        })),
        inputs: cleanInputArray(form.inputs).map(i => ({
          binding: i.key,
          label: i.value,
          type: i.type,
        })),
        ...(!isEmpty(form.createOptions) && currentUser?.isSysAdmin
          ? { createOptions: JSON.parse(form.createOptions) }
          : {}),
      });
    } else {
      const tarFile = form.tarFile[0];
      let key;
      if (tarFile) {
        ({ key } = await upload(tarFile, { extension: 'tgz' }));
      }
      updateDockerComposeSkillVersion.mutate({
        id: skillVersionId,
        key,
        env,
        port: _isFinite(port) ? port : null,
      });
    }
  });

  const loading =
    fetchError ||
    fetching ||
    updateSkillVersion.isPending ||
    updateDockerComposeSkillVersion.isPending ||
    uploadState.fetching;

  const itemNotFound = useItemNotFound({
    fetching,
    id: data?.skillVersion?.id,
  });
  if (itemNotFound) {
    return <ItemNotFound id={skillVersionId} type="Version" />;
  }

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Edit Version"
        icon="Stack"
        loading={loading}
        renderActions={() =>
          !isLastVersion ? (
            <Button
              variant="secondary"
              size="xs"
              onClick={handleDeleteVersion}
              loading={deleteSkillVersion.isPending}
              icon="Trash"
            >
              Delete
            </Button>
          ) : null
        }
      />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Update Version">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Version">
            {fetchError.message}
          </Message>
        )}
        {data && !isDockerCompose && (
          <>
            <div>
              <SkillFormTabs
                tab={tab}
                onChange={setTab}
                tabs={['container', 'inputs', 'outputs', 'env', 'privileges']}
              />
            </div>

            <div>
              <ValidationError name="repository.uri" errors={errors} />
              {Object.keys(errors).some(e => Object.keys(PRIVILEGES_FIELD_NAMES).includes(e)) && (
                <ValidationError
                  message={`Missing or invalid fields: ${Object.keys(errors || [])
                    .map(e => PRIVILEGES_FIELD_NAMES[e])
                    .join(', ')}`}
                />
              )}
            </div>

            <div>
              <SkillContainerFields
                visible={isActiveTab(tab, 'container')}
                loading={loading}
                register={register}
              />
              <SkillInputFields
                visible={isActiveTab(tab, 'inputs')}
                loading={loading}
                control={control}
              />
              <SkillOutputFields
                visible={isActiveTab(tab, 'outputs')}
                loading={loading}
                control={control}
                errors={errors}
              />
              <SkillEnvFields
                visible={isActiveTab(tab, 'env')}
                loading={loading}
                control={control}
              />
              <SkillPrivilegesFields
                visible={isActiveTab(tab, 'privileges')}
                loading={loading}
                form={form}
              />
            </div>

            <RawConfig form={form} setError={setError} loading={loading} />
          </>
        )}
        {data && isDockerCompose && (
          <>
            <div>
              <SkillFormTabs tab={tab} onChange={setTab} tabs={['compose', 'env', 'privileges']} />
            </div>
            <SkillComposeFields
              loading={loading}
              register={register}
              errors={errors}
              watch={watch}
              visible={isActiveTab(tab, 'compose')}
            />
            <SkillEnvFields loading={loading} control={control} visible={isActiveTab(tab, 'env')} />
            <SkillComposePrivilegesFields
              loading={loading}
              visible={isActiveTab(tab, 'privileges')}
              form={form}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
