import { isFunction } from 'lodash';
/**
 * Renders children components based on their type and props.
 * @param {string[]} componentTypes - Array of component type names to match against
 * @param {React.ReactNode} children - The children to render
 * @param {Object} providedProps - Additional props to pass to function children
 * @returns {React.ReactNode} The rendered children
 */ export default function renderChildren(componentTypes, children, providedProps) {
    var _children_type, _children_props;
    if (componentTypes.includes(children === null || children === void 0 ? void 0 : (_children_type = children.type) === null || _children_type === void 0 ? void 0 : _children_type.name) && isFunction((_children_props = children.props) === null || _children_props === void 0 ? void 0 : _children_props.children)) {
        return children.props.children(providedProps);
    }
    if (isFunction(children)) {
        return children(providedProps);
    }
    return children;
}
