import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import * as Q from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { GraphQLClient } from 'graphql-request';
import gql from 'graphql-tag';
import { isError, isFunction, isString } from 'lodash';
import { useEffect, useRef } from 'react';
import { useDeepCompareEffect } from 'react-use';

import auth from 'config/auth';
import cleanObject from 'lib/clean-object';
import OrganizationClient from 'lib/organization-client';

import { ApiError } from './errors';

const responseMiddleware = response => {
  if (!isError(response)) {
    const version = response.headers.get('App-Version');
    if (version && response.status === 200 && version !== process.env.REACT_APP_VERSION) {
      window.localStorage.setItem('version-update', 'true');
    }
  }
};
export const apiClient = new GraphQLClient(process.env.REACT_APP_API_URL, { responseMiddleware });
export const queryClient = new Q.QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const localStoragePersister = createSyncStoragePersister({ storage: window.localStorage });

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
  buster: process.env.REACT_APP_VERSION || 'v0',
  dehydrateOptions: {
    shouldDehydrateQuery: ops => !!ops.options.persist,
  },
});

export function QueryProvider(props) {
  return <Q.QueryClientProvider {...props} client={queryClient} />;
}

export const q = Q;
let optraPortalLastLoginTs = 0;

export async function api(_query, variables, otherHeaders) {
  const query = gql(_query);
  const session = await auth.renewSession().catch(() => {
    auth.login();
  });

  const organizationId = OrganizationClient.get();

  try {
    return await apiClient.request(
      query,
      variables,
      cleanObject({
        authorization: `Bearer ${session?.idToken}`,
        organization: organizationId,
        ...otherHeaders,
      }),
    );
  } catch (err) {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      // It might be useful to see full error outputs in development
      console.log('An API request resulted in an error. Response: ', err.response);
    }

    // We don't need to display anything but the message.
    const message = isString(err?.response?.errors?.[0])
      ? err?.response?.errors?.[0]
      : err?.response?.errors?.[0]?.message;
    const code = err?.response?.errors?.[0]?.extensions?.code;
    if (code === 'UNAUTHENTICATED') {
      // Mitigate overlapping login calls. Each call creates and drops a naunce
      // in a cookie. At times we have enough overlap to cause a 494 error due to
      // the number of cookies.
      const currentTs = Date.now();
      if (currentTs - optraPortalLastLoginTs > 3000) {
        optraPortalLastLoginTs = currentTs;
        auth.login();
      }
    } else if (code === 'AUTHENTICATION_METHOD') {
      auth.logout();
    }
    throw new ApiError(message || 'API request resulted in an error', code);
  }
}

export function timeout(fn, ops) {
  const { ms = 1500, errorMessage = 'Timed Out!' } = ops || {};
  return Promise.race([
    new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error(errorMessage)), ms);
    }),
    fn,
  ]);
}

export function useOnSuccess(fnOrFnConfig, reactQueryResult, otherDeps) {
  const fn = isFunction(fnOrFnConfig) ? fnOrFnConfig : fnOrFnConfig?.fn;
  const queryKey = fnOrFnConfig?.queryKey || ['$$NONE'];

  const didRun = useRef(false);
  useDeepCompareEffect(() => {
    didRun.current = false;
  }, [queryKey]);

  useEffect(() => {
    if (didRun.current || !reactQueryResult?.isSuccess) return;
    didRun.current = true;

    fn();
    // eslint-disable-next-line
  }, [didRun.current, fn, reactQueryResult.isSuccess, ...(otherDeps || [])]);
}
