import cx from 'classnames';

import { Card, DetailHeading, UiState, Badge, Button, Icon, IconButton, Spinner } from '@optra/kit';

export default function WorkflowCard(props) {
  const {
    heading,
    icon,
    count,
    loading,
    disabled,
    expanded = true,
    allowEmptyState = true,
    children,
    plusUrl,
    ...rest
  } = props;
  const allowAdding = !!(!disabled && plusUrl);
  const showEmptyState = !!(allowEmptyState && !count);
  const showHeadingPlusButton = !!(allowAdding && (!showEmptyState || !expanded));

  return (
    <Card
      variant="subtle"
      className={cx(
        'relative',
        'col-span-10',
        'flex flex-col items-stretch',
        expanded && 'max-h-[450px] min-h-[170px] lg:h-[450px]',
        'p-5 pb-0',
        'overflow-hidden',
      )}
      noPadding
      {...rest}
    >
      <div className="flex-0 flex items-center justify-between pb-5">
        <div className="inline-flex items-center space-x-2">
          <Icon name={icon} color="primary" weight="duotone" />
          <DetailHeading level={3} variant="loud">
            {heading}
          </DetailHeading>
          {!!loading
            ? !expanded && <Spinner color="gradient" />
            : !!count && (
                <Badge size="md" className="animate-fade-in">
                  <span className="text-sm">{count}</span>
                </Badge>
              )}
        </div>
        {showHeadingPlusButton && <IconButton to={plusUrl} icon="Plus" variant="tertiary" />}
      </div>
      {expanded && (
        <div className="flex-1 overflow-auto scroll-bar-hidden pb-3 -mx-[2px]">
          {!!loading ? (
            <UiState center />
          ) : showEmptyState ? (
            <UiState
              variant="empty"
              IconComponent={
                <Icon
                  name={icon}
                  className="h-10 w-10 opacity-20 dark:opacity-30"
                  weight="duotone"
                />
              }
              text={`No ${heading}`}
              center
            >
              {allowAdding && (
                <Button to={plusUrl} icon="Plus" variant="tertiary" size="xs">
                  Add
                </Button>
              )}
            </UiState>
          ) : (
            <div className="animate-fade-in relative p-[2px]">{children}</div>
          )}
        </div>
      )}
      <div
        className={cx(
          'absolute hidden lg:block',
          'w-full h-14',
          'left-0 bottom-0',
          'bg-gradient-to-t via-20%',
          'from-white via-white',
          'dark:from-black-700 dark:via-black-700',
          'pointer-events-none',
        )}
      />
    </Card>
  );
}
